<template>
  <div class="container containers"
       v-loading="loading">
    <ul class="productlist clearfix wow"
        v-if="products.length">
      <li v-for="(item,index) in products"
          :key="index">
        <div class="box"
             @click="buy(item)">
          <div class="pic goods-img">
            <el-image class="el-image"
                      :src="item.img"
                      alt=""
                      lazy>
            </el-image>
          </div>
          <div class="text">
            <div class="bt"
                 :title="item.title">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:item.title}}</div>
            <div class="span"></div>
            <div class="price">{{item.discount_price?moneyfilter(item.discount_price):moneyfilter(item.price)}}</div>
            <div class="more"
                 @click="buy(item)">{{ $t('product.ljgm') }}</div>
          </div>
        </div>
      </li>
    </ul>
    <div class="goods-box"
         v-else><img class="no_goods"
           src="../../assets/product/noGoods.png"
           alt=""></div>
    <div class="page clearfix wow fadeInUp2"
         data-wow-duration="1.5s"
         data-wow-delay="0.3s">
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      productTop: {},
      classifyImg: {},
      langtype: null,
      products: [],
      loading: false,
      btnsrc: null,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  name: "product",
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  created () {
    document.title = window.document.domain == "www.babazhn.com" ? '深圳把把智能科技有限公司' : window.document.domain == 'xn--drua831axt0b.xn--fiqs8s' ? '把把智能' : '产品中心首页'
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    // this.langtype=localStorage.getItem('lang')
    console.log(this.langtype)
    let _this = this
    this.getProductList()
    let sharing = localStorage.getItem("sharing")
    setTimeout(() => {
      console.log(this.classifyImg);

    }, 2000)
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", function (e) {
      //e.key : 是值发生变化的key
      if (e.key === "sharing") {
        _this.ProductCenter()
        setTimeout(item => {
          _this.classifyImg.items.forEach((item) => {
            if (item.name == e.newValue) {
              if (item) {
                _this.platformTitle(item)
              }
            }
          })
        }, 500)
      }
    })
  },

  mounted () {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var lang = localStorage.getItem('lang')
      this.btnsrc = require('../../assets/product/once_' + lang + '.png')
    });
  },
  methods: {
    todetails (e) {
      if (e.expansion) {
        document.location.href = e.expansion
      }
    },
    handleClickSlide () {
      // console.log(222);
    },
    async getProductList () {
      let productTop = await this.api.get(this.apiPath.productTop, {});
      console.log(productTop)
      this.productTop = productTop
      let classifyImg = await this.api.get(this.apiPath.classifyImg, {});
      // console.log(classifyImg);
      classifyImg.items.sort((a, b) => a.index - b.index)
      console.log(classifyImg.items)
      for (var item of classifyImg.items) {
        item.names = JSON.parse(item.names)
      }
      // let classifyImgh = await this.api.get(this.apiPath.classifyImgh,{});
      // 合并title图片
      // classifyImg.items.forEach((i,index) => {
      //   i.imgh = classifyImgh.items[index].img
      // });
      this.classifyImg = classifyImg
      this.loading = true
      let allProducts = await this.api.get(this.apiPath.allProducts, {});
      console.log(allProducts)
      this.products = allProducts
      this.loading = false
    },
    async platformTitle (i) {
      console.log(i)
      switch (i.id) {
        case 23:
          this.$router.push({
            path: '/safetyHat'
          })
          break;
        case 21:
          this.$router.push({
            path: '/smokeDetector'
          })
          break;
        case 19:
          this.$router.push({
            path: '/watch'
          })
          break;
        case 10:
          this.$router.push({
            path: '/smartElectricity'
          })
          break;
        case 41:
          this.$router.push({
            path: '/waterMeter'
          })
          break;
        case 42:
          this.$router.push({
            path: '/platForm'
          })
          break;
        case 45:
          this.$router.push({
            path: 'touringCar'
          })
          break;
        case 48:
          this.$router.push({
            path: 'fireEngine'
          })
          break;
        default:
          let twoFireProducts = await this.api.get(this.apiPath.twoFireProducts + '?id=' + `${i.id}`, {});
          console.log(twoFireProducts)
          //是否有二级分类
          if (twoFireProducts.items.length) {
            // this.classifyImg = twoFireProducts
            let fireProducts = await this.api.get(this.apiPath.fireProducts + twoFireProducts.items[0].id + '/detailSPU', {});
            if (fireProducts.items.length) {
              console.log(fireProducts.items)
              fireProducts.items.sort((a, b) => a.sort2 - b.sort2)
              this.products = fireProducts.items
            } else {
              this.products = []
            }
          } else {
            // let fireProducts = await this.api.get(this.apiPath.fireProducts + i.id + '/detailSPU',{});
            // this.products = fireProducts.items
            // console.log(fireProducts);
          }
      }

    },
    async ProductCenter () {
      let allProducts = await this.api.get(this.apiPath.allProducts, {});
      this.products = allProducts
      this.getProductList()
    },
    buy (item) {
      //立即购买
      this.$router.push({
        path: this.$router.history.current.matched[0].path + "/goodsDetail?id=" + item.id,
      });
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
@import url("../../views/products/common.css");
.goods-img {
  background: #fff;
  border-bottom: 6px solid #f7f7f7;
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.wwq1 {
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  left: 59%;
  transform: translate(-50%, 0);
  bottom: 6%;
  width: 9%;
  height: 8%;
}
.goods-box {
  text-align: center;
  .no_goods {
    width: 600px;
    height: 400px;
  }
}

.product {
  .inbanner {
    margin-top: -35px;
  }
  #er5 {
    cursor: pointer;
    .pic {
      background: #fff;
    }
  }
}
.second-nav {
  padding: 0 130px;
}
.containers {
  padding: 0 130px;
}
.inmain {
  #yi1 {
    width: 200px;
    cursor: pointer;
    display: block;
    background: url("../../assets/product/title.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: initial;
    background-position: center;
  }
}
.productlist {
  cursor: pointer;
  .price {
    color: red;
  }
}
@media screen and (max-width: 750px) {
  .top {
    .title {
      margin-left: 3%;
    }
  }
  .second-nav {
    padding: 0;
    width: 100%;
    overflow-x: auto;
    padding: 0 10px;
    ul {
      display: flex;
      .box {
        width: 60px;
      }
    }
    #yi1 {
      display: none;
    }
  }
  .inmain {
    .containers {
      padding: 0;
    }
    .productlist li {
      width: 49%;
      padding: 0 10px;
      .pic {
        height: 120px;
      }
    }
  }
  .inouts {
    display: none !important;
  }
  .second-nav {
    margin-bottom: 10px !important;
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
